import * as React from "react";
import "../../views/Alerts.scss";

//components
import { Divider, Modal, IconButton, Button, Spinner } from "../Atomics";
import { CFormCheck } from "@coreui/react";
import { useHistory } from "react-router-dom";

//services
import { AlertTypeResponse } from "../../services/types";
import {
  setAlertDefaultById,
  deleteAlertType, setHomeAlert, setAutoAlert, setImmediateAlert,
} from "../../services/alertServices";

//icons
import FacebookIcon from "../../assets/fb_icon.svg";
import TwitterIcon from "../../assets/tw_icon.svg";
import InstagramIcon from "../../assets/ig_icon.svg";
import HandleUIError from "../../utils/HandleUIError";
import { cilX, cilSettings, cilPen, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import toast from "react-hot-toast";
import usePermission from "../../hooks/usePermission";
import { createActivityReq } from "../../services/activityLogService";

interface Props {
  showModal: (
    alertId: string,
    name: string,
    icon: any,
    type: any,
    color: string,
    openedFrom: number
  ) => void;
  alertId: string;
  alertType: AlertTypeResponse;
  refreshAlerts: () => Promise<void>;
  alerts: any
}

const AlertSettingsItem: React.FC<Props> = ({
  alertType,
  showModal,
  alertId,
  refreshAlerts,
  alerts,
}) => {
  const { checkPermission } = usePermission()
  const [visibleSocialMedia, setVisibleSocialMedia] = React.useState(false);
  const [loadingDefault, setLoadingDefault] = React.useState(false);
  const [loadingDefault2, setLoadingDefault2] = React.useState(false);
  const [loadingDefault3, setLoadingDefault3] = React.useState(false);
  const [loadingDefault4, setLoadingDefault4] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);

  const history = useHistory();

  async function onCheckedIsDefault() {
    setLoadingDefault(true);
    try {
      await setAlertDefaultById(alertType.id);
      await refreshAlerts();
      setLoadingDefault(false);
      await createActivityReq('Alert Setting Updated Successfully')
    } catch (e) {
      setLoadingDefault(false);
      HandleUIError(e);
    }
  }

  async function onCheckedIsHomeAlertDefault(event: any) {
    const isMoreThanThree = alerts?.filter((item: any) => item?.isHomeAlert)?.length - 1
    const checked: any = event?.target?.checked
    console.log('three', isMoreThanThree)
    if (checked) {
      if (isMoreThanThree < 2) {
        setLoadingDefault2(true);
        try {
          await setHomeAlert(alertType.id, checked);
          await refreshAlerts();
          setLoadingDefault2(false);
          await createActivityReq('Alert Setting IsHomeAlert Status Changed Successfully')
        } catch (e) {
          setLoadingDefault2(false);
          HandleUIError(e);
        }
      } else {
        toast.error('Please Checked Less Than 4 Home Alerts');
      }
    } else {
      setLoadingDefault2(true);
      try {
        await setHomeAlert(alertType.id, checked);
        await refreshAlerts();
        setLoadingDefault2(false);
        await createActivityReq('Alert Setting IsHomeAlert Status Changed Successfully')
      } catch (e) {
        setLoadingDefault2(false);
        HandleUIError(e);
      }
    }
  }

  async function onCheckedAutoSend(event: any) {
    setLoadingDefault3(true);
    const checked: any = event?.target?.checked
    try {
      await setAutoAlert(alertType.id, checked);
      await refreshAlerts();
      setLoadingDefault3(false);
      await createActivityReq('Alert Setting AutoSend Status Changed Successfully')
    } catch (e) {
      setLoadingDefault3(false);
      HandleUIError(e);
    }
  }

  async function onCheckedImmediateSend(event: any) {
    setLoadingDefault4(true);
    const checked: any = event?.target?.checked
    try {
      await setImmediateAlert(alertType.id, checked);
      await refreshAlerts();
      setLoadingDefault4(false);
      await createActivityReq('Alert Setting Immediate Status Changed Successfully')
    } catch (e) {
      setLoadingDefault4(false);
      HandleUIError(e);
    }
  }

  async function onPressDelete(alertTypeId: string) {
    setLoadingDelete(true);
    try {
      await deleteAlertType(alertTypeId);
      await refreshAlerts();
      await createActivityReq('Alert Setting Removed Successfully')
      setLoadingDelete(false);
    } catch (e) {
      setLoadingDelete(false);
      HandleUIError(e);
    }
  }

  function closeSocialMedia() {
    setVisibleSocialMedia(false);
  }

  function goToAlertActionView() {
    history.push("/alert-type-action", alertType);
  }

  function isDefault() {
    if (typeof alertType.isDefault === "string") {
      return alertType.isDefault === "1";
    }
    return alertType.isDefault;
  }

  function isHomeAlertDefault() {
    if (typeof alertType.isHomeAlert === "string") {
      return alertType.isHomeAlert === "1";
    }
    return alertType.isHomeAlert;
  }

  function isAutoSend() {
    if (typeof alertType.autoSend === "boolean") {
      return alertType.autoSend === true;
    }
    return alertType.autoSend;
  }

  function isImmediateSend() {
    if (typeof alertType.immediateSend === "boolean") {
      return alertType.immediateSend === true;
    }
    return alertType.autoSend;
  }



  return (
    <>
      <div className="d-flex align-items-center justify-content-between py-3 px-3">
        <div className="d-flex align-items-center w-50">
          <p className="text-light fs-5 me-5 alert-type-title-settings w-100">
            {alertType.name}
          </p>
        </div>
        <div className="d-flex align-items-center w-50">
          <p className="text-light fs-5 me-5 alert-type-title-settings w-100">
            {alertType?.alert_category?.name
              ? alertType.alert_category.name
              : "-"}
          </p>
        </div>
        {checkPermission("Create and Edit Setting") && (
          <div className="d-flex align-items-center w-50">
            {!loadingDefault2 ? (
              <CFormCheck checked={isHomeAlertDefault()} onChange={onCheckedIsHomeAlertDefault} />
            ) : (
              <Spinner className="is-defualt-spinner" />
            )}
          </div>
        )}
        {checkPermission("Create and Edit Setting") && (
          <div className="d-flex align-items-center w-50">
            {!loadingDefault3 ? (
              <>
                {isHomeAlertDefault() && (
                  <CFormCheck checked={isAutoSend()} onChange={onCheckedAutoSend} />
                )}
              </>
            ) : (
              <Spinner className="is-defualt-spinner" />
            )}
          </div>
        )}
        {checkPermission("Create and Edit Setting") && (
          <div className="d-flex align-items-center w-50">
            {!loadingDefault4 ? (
              <>
                {isHomeAlertDefault() && (
                  <CFormCheck
                    checked={isImmediateSend()}
                    onChange={onCheckedImmediateSend} />
                )}
              </>
            ) : (
              <Spinner className="is-defualt-spinner" />
            )}
          </div>
        )}
        <div
          className="d-flex align-items-center cursor-pointer noselect-text"
          onClick={goToAlertActionView}
        >
          <CIcon className="text-light m-0 me-1" icon={cilSettings} />
          <p className="text-light m-0">Actions</p>
        </div>

        <div className="d-flex align-items-center">
          {checkPermission("Create and Edit Setting") && (
            <div className="m-0 me-4">
              {!loadingDefault ? (
                <CFormCheck checked={isDefault()} onChange={onCheckedIsDefault} />
              ) : (
                <Spinner className="is-defualt-spinner" />
              )}
            </div>
          )}
          {checkPermission("Create and Edit Setting") && (
            <CIcon
              icon={cilPen}
              className="perimeters-icon m-0"
              size="lg"
              onClick={() =>
                showModal(
                  alertId,
                  alertType.name,
                  alertType.icon,
                  alertType.alert_category,
                  alertType.color,
                  2
                )
              }
            />
          )}

          {checkPermission("Delete Setting") && (
            <>
              {!loadingDelete ? (
                <CIcon
                  icon={cilTrash}
                  className="perimeters-icon m-0 ms-1"
                  size="lg"
                  onClick={() => onPressDelete(alertId)}
                />
              ) : (
                <Spinner className="is-defualt-spinner" />
              )}
            </>
          )}

        </div>
      </div>
      <Divider horizontalSpace={5} />
      <Modal visible={visibleSocialMedia} onPressOut={closeSocialMedia}>
        <div className="new-staff-container social-media-settings-container">
          <div className="d-flex justify-content-between new-template-header">
            <p className="fs-5">
              Select the social networks for which to notify
            </p>
            <IconButton icon={cilX} onClick={closeSocialMedia} />
          </div>
          <div className="ms-4 mt-2 d-flex justify-content-between">
            <div>
              <div className="d-flex align-items-center">
                <CFormCheck label="Facebook" className="me-2 mb-2" />
                <img src={FacebookIcon} alt="" className="social-media-icon" />
              </div>
              <div className="d-flex align-items-center">
                <CFormCheck label="Twitter" className="me-2 mb-2" />
                <img src={TwitterIcon} alt="" className="social-media-icon" />
              </div>
              <div className="d-flex align-items-center">
                <CFormCheck label="Instagram" className="me-2 mb-2" />
                <img src={InstagramIcon} alt="" className="social-media-icon" />
              </div>
            </div>
            <div className="d-flex align-items-end me-4 mb-2">
              <Button onClick={closeSocialMedia} className="px-5">
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AlertSettingsItem;
