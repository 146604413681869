import * as React from "react";
import "./Staff.scss";

//components
import { Pagination } from "../components";
import { NewMessage } from "../components/messages";
import { PageContainer } from "../components/Atomics";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";
import HandleUIError from "../utils/HandleUIError";
// types
import { Parents } from "../components/types";
// services
import { getParents, searchParents } from "../services/parentsService";
import ParentsList from "../components/parents/ParentsList";
import NewParent from "../components/parents/NewParent"
import useDebounce from "../hooks/useDebounce";
import usePermission from "../hooks/usePermission";
import NewParentComp from "./NewParentComp";

const ParentsComp: React.FC = () => {
  const { checkPermission } = usePermission()
  const [visibleNewParent, setVisibleNewParent] = React.useState(false);
  const [currentParents, setCurrentParents] = React.useState<Array<Parents>>([]);
  const [parents, setParents] = React.useState<Array<Parents>>();
  const [numberOfTotalParents, setNumberOfTotalParents] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [parentsPageSize] = React.useState<number>(7);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [pageNumbers, setPageNumbers] = React.useState<number>(1);
  const [refreshPage, setRefreshPage] = React.useState<boolean>(false);

  const debouncedSearch = useDebounce(searchValue, 300);

  function searchParentsDebounced() {
    searchParents(parentsPageSize, 1, searchValue).then((parentsFound) => {
      setCurrentPage(1)
      setCurrentParents(parentsFound.data);
      setPageNumbers(parentsFound.numberOfTotalParents / parentsPageSize);
      setNumberOfTotalParents(parentsFound.numberOfTotalParents);
    })
      .catch((e) => {
        HandleUIError(e);
      });
  }

  React.useEffect(
    function () {
      if (debouncedSearch) {
        searchParentsDebounced();
      } else {
        searchParents(parentsPageSize, 1, searchValue).then((parentsFound) => {
          setCurrentPage(1)
          setCurrentParents(parentsFound.data);
          setPageNumbers(parentsFound.numberOfTotalParents / parentsPageSize);
          setNumberOfTotalParents(parentsFound.numberOfTotalParents);
        }).catch((e) => {
          HandleUIError(e)
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch]
  );

  React.useEffect(() => {
    refreshParents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  React.useEffect(function () {
    getParents(parentsPageSize, 1).then((res) => {
      setParents(res);
    }).catch((e) => {
      HandleUIError(e)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeSearchValue(e: any) {
    setSearchValue(e.target.value);
  }


  //this function is treggered by pagination
  async function refreshParents() {
    setLoading(true);
    try {
      let parentsFound: any = await searchParents(
        parentsPageSize,
        currentPage,
        searchValue
      );
      setCurrentParents(parentsFound.data);
      setPageNumbers(parentsFound.numberOfTotalParents / parentsPageSize);
      setNumberOfTotalParents(parentsFound.numberOfTotalParents);
      setLoading(false);
    } catch (e) {
      HandleUIError(e);
      setLoading(false);
    }
  }

  function onPressAdd() {
    setVisibleNewParent(!visibleNewParent);
  }

  return (

    <>


      <div className=" bg-main pb-5 over-flow-auto h-100">
        <PageContainer className="page-container-staff d-flex flex-column justify-content-start pb-3 mx-4">
          <div className="d-flex flex-custom-row flex-column justify-content-end staff-menu">
            <div className="d-flex justify-content-between align-items-center flex-dir-column">
              <div className="wrap mg-bot mg-lt center-text d-none">
                <p className="text-light me-2">Search:</p>
                <input
                  value={searchValue}
                  onChange={onChangeSearchValue}
                  className="input-search"
                  type="text"
                />
              </div>
              <div className="d-flex align-self-end mt-3 mt-xxl-0 buttons-right wrap">
                {checkPermission("Create and Edit Parent") && (
                  <div
                    className="add-icon-container mg-lt add-icon-staff"
                    onClick={onPressAdd}
                  >
                    <CIcon icon={cilPlus} size="xl" className="add-icon" />
                  </div>
                )}
              </div>
            </div>
          </div>


          <NewParentComp refreshPage={refreshPage} />

        </PageContainer>
        <NewParent
          visible={visibleNewParent}
          closeModal={onPressAdd}
          refreshParents={refreshParents}
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
        />
        {/* <NewMessage /> */}
      </div>
    </>
  );
};

export default ParentsComp;