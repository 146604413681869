import * as React from "react";
import "./Staff.scss";

//components
import { Pagination } from "../components";
import { NewMessage } from "../components/messages";
import { PageContainer } from "../components/Atomics";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";
import HandleUIError from "../utils/HandleUIError";
// types
import { Roles } from "../components/types";
// services
import { getEmergency, searchEmergency } from "../services/emergencyService";
import EmergencyList from "../components/emergency/EmergencyList";
import NewEmergency from "../components/emergency/NewEmergency";
import useDebounce from "../hooks/useDebounce";
import { useEffect } from "react";
import usePermission from "../hooks/usePermission";
import { getEmergencyRelation } from "../services/emergencyRelationService";
import EmergencyListRelation from "../components/emergency/EmergencyListRelation";
import NewEmergencyRelation from "../components/emergency/NewEmergencyRelation";
import { getNewParents } from "../services/newParentService";
import NewParentsList from "../components/emergency/NewParentsList";

interface Props {
  refreshPage: boolean;
}

const NewParentComp: React.FC<Props> = ({refreshPage}) => {
  const { checkPermission } = usePermission()
  const [visibleNewParent, setVisibleNewParent] = React.useState(false);
  const [currentParents, setCurrentParents] = React.useState<any>([]);
  const [parents, setParents] = React.useState<any>();
  const [numberOfTotalParents, setNumberOfTotalParents] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const MAX_SIZE_ORGANIZATIONS = 10;
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState<string>("");

  const debouncedSearch = useDebounce(searchValue, 300);

  const reFetch = (limit: any, page: any) => {
    setLoading(true);
    getNewParents(limit, page)
      .then((res: any) => {
        console.log('rows', res)
        setCurrentParents(res?.rows);
        setNumberOfPages(res?.count / MAX_SIZE_ORGANIZATIONS);
      })
      .catch((e) => {
        HandleUIError(e);
      })
      .finally(() => {
        setLoading(false)
      })
  }

  React.useEffect(() => {
    reFetch(MAX_SIZE_ORGANIZATIONS, currentPage);
  }, []);


  React.useEffect(() => {
    reFetch(MAX_SIZE_ORGANIZATIONS, currentPage)
  }, [currentPage])

  React.useEffect(() => {
    reFetch(MAX_SIZE_ORGANIZATIONS, currentPage)
  }, [refreshPage])


  function onChangeSearchValue(e: any) {
    setSearchValue(e.target.value);
  }

  console.log("currentParents", currentParents);

  function onPressAdd() {
    setVisibleNewParent(!visibleNewParent);
  }

  return (
    <>

      <div>
        <NewParentsList
          parents={currentParents}
          loading={loading}
          refreshParents={() => reFetch(MAX_SIZE_ORGANIZATIONS, currentPage)}
        />
      </div>

      <div className="py-3">
        {numberOfPages > 1 ? (
          <Pagination
            numberOfPages={numberOfPages}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        ) : (
          <></>
        )}
      </div>
      <NewEmergencyRelation
        visible={visibleNewParent}
        closeModal={onPressAdd}
        refreshParents={() => reFetch(MAX_SIZE_ORGANIZATIONS, currentPage)}
      />
      <NewMessage />
    </>
  );
};

export default NewParentComp;
