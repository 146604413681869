import * as React from "react";
import "./Alerts.scss";

//components
import { Pagination } from "../components";
import { AlertInformation, AlertSettings } from "../components/alert-history";
import { PageContainer, MenuHeader } from "../components/Atomics";
import {
  GroupButtons,
  ButtonForGroup,
} from "../components/Atomics/GroupButtons";
import { useLocation } from "react-router-dom";
import { CFormSelect, CSpinner } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilCalendar } from "@coreui/icons";
import DatePicker from "react-datepicker";

//services
import {
  getAlerts as getAlertTypes,
  getHistoryOfAlerts,
} from "../services/alertServices";
import { AlertHistoryResponse } from "../services/types";

import "react-datepicker/dist/react-datepicker.css";
import HandleUIError from "../utils/HandleUIError";
import AlertCategories from "../components/alert-history/AlertCategories";
import EmergencyAlert from "../components/alert-history/EmergencyAlert";
import MapModal from "../components/MapModal";
import { useState } from "react";
import usePermission from "../hooks/usePermission";

const Alerts: React.FC = () => {
  const { checkPermission } = usePermission()
  const locationState = useLocation().state as any;
  const [visibleMap, setVisibleMap] = useState(false)
  const [isEdit, setIsEdit] = useState<any>(null)
  const [startDate, setStartDate] = React.useState<Date | null>(new Date());
  const [lastDate, setLastDate] = React.useState<Date | null>(new Date());
  const [visibleCalendarSince, setVisibleCalendarSince] = React.useState(false);
  const [visibleCalendarUntil, setVisibleCalendarUntil] = React.useState(false);
  const [listSettings, setListSettings] = React.useState(
    locationState && locationState.settings
      ? { list: false, settings: true, tab: false, alert: false }
      : { list: true, settings: false, tab: false, alert: false }
  );
  const [currentPage, setCurrentPage] = React.useState(1);
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [alerts, setAlerts] = React.useState<AlertHistoryResponse[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [alertTypesFilters, setAlertTypesFilters] = React.useState<
    { id: number; name: string }[]
  >([]);
  const [filterSelected, setFilterSelected] = React.useState("all");
  const PAGE_SIZE = 10;

  React.useEffect(function () {
    generateFilters();
  }, []);

  React.useEffect(
    function () {
      getAlerts();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, filterSelected, startDate, lastDate]
  );

  function onChangeFilter(e: any) {
    setCurrentPage(1);
    setFilterSelected(e.target.value);
  }

  async function getAlerts() {
    setIsLoading(true);
    try {
      startDate && startDate.setHours(0, 0, 0, 0);
      lastDate && lastDate.setHours(23, 59, 59, 999);
      const alerts = await getHistoryOfAlerts(
        currentPage,
        PAGE_SIZE,
        filterSelected,
        startDate?.toISOString(),
        lastDate?.toISOString()
      );
      setAlerts(alerts.data);
      setNumberOfPages(Math.ceil(alerts.total / PAGE_SIZE));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      HandleUIError(e);
    }
  }

  async function generateFilters() {
    try {
      const alertTypes = await getAlertTypes();
      const filters = alertTypes.data.map((alertType) => {
        return { name: alertType.name, id: alertType.id };
      });
      setAlertTypesFilters(filters);
    } catch (e) {
      HandleUIError(e);
    }
  }

  function changeToAlert() {
    setListSettings({
      list: false,
      settings: false,
      tab: false,
      alert: true,
    });
  }

  function changeToList() {
    setListSettings({
      list: true,
      settings: false,
      tab: false,
      alert: false,
    });
  }

  function changeToSettings() {
    setListSettings({
      list: false,
      settings: true,
      tab: false,
      alert: false,
    });
  }
  function changeToNewTab() {
    setListSettings({
      list: false,
      settings: false,
      tab: true,
      alert: false,
    });
  }

  function toggleCalendarSince() {
    setVisibleCalendarSince(!visibleCalendarSince);
  }

  function toggleCalendarUntil() {
    setVisibleCalendarUntil(!visibleCalendarUntil);
  }

  const setMapData = (data: any) => {
    setIsEdit(data)
    setVisibleMap(true)
  }

  return (
    <div className="bg-main pb-3 over-flow-auto h-100">
      <PageContainer className="alert-page-container px-2 mx-2 px-sm-4 mx-sm-4 d-flex flex-column justify-content-between pb-3 alert-page-container">
        <div>
          <div className="row g-2 justify-content-end">
            <div className="col-lg-auto me-auto">
              <GroupButtons className="p-0">
                {/*<ButtonForGroup*/}
                {/*    label="Emergency Alert"*/}
                {/*    onClick={changeToAlert}*/}
                {/*    checked={listSettings.alert}*/}
                {/*/>*/}
                {checkPermission('View History') && (
                  <ButtonForGroup
                    label="Other Alerts"
                    onClick={changeToList}
                    checked={listSettings.list}
                  />
                )}
                {checkPermission('View Settings') && (
                  <ButtonForGroup
                    label="Settings"
                    onClick={changeToSettings}
                    checked={listSettings.settings}
                  />
                )}
                {checkPermission('View Categories') && (
                  <ButtonForGroup
                    label="Categories"
                    onClick={changeToNewTab}
                    checked={listSettings.tab}
                  />
                )}
              </GroupButtons>
            </div>
            <div className="col-lg-3">
              {listSettings.list && (
                <div className="row align-items-center w-100">
                  <div className="col-auto">
                    <p className="text-light pe-3">Types: </p>
                  </div>
                  <div className="col">
                    <CFormSelect
                      value={filterSelected}
                      onChange={onChangeFilter}
                      className="template-select form-select w-100 custom-width"
                    >
                      <option value={"all"}>All</option>
                      {alertTypesFilters.map(function (filter) {
                        return <option value={filter.id}>{filter.name}</option>;
                      })}
                    </CFormSelect>
                  </div>
                </div>
              )}
            </div>
            <div className="col-auto">
              {listSettings.list && (
                <div className="d-flex d-md-custom-flex justify-content-custom w-100">
                  <div className="d-flex mg-r">
                    <p className="d-flex text-light align-items-center mg-r-xs">
                      Since:
                    </p>
                    <div
                      className="calendar-icon-container cursor-pointer mt-1"
                      onClick={toggleCalendarSince}
                    >
                      <CIcon
                        size="xl"
                        icon={cilCalendar}
                        className="text-light"
                      />
                    </div>
                    <DatePicker
                      onChangeRaw={(e) => e.preventDefault()}
                      onClickOutside={toggleCalendarSince}
                      onInputClick={toggleCalendarSince}
                      open={visibleCalendarSince}
                      className="calendar-input mt-1"
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        toggleCalendarSince();
                        toggleCalendarUntil();
                      }}
                    />
                  </div>
                  <div className="d-flex">
                    <p className="d-flex text-light align-items-center mg-r-xs">
                      Until:
                    </p>
                    <div
                      className="calendar-icon-container cursor-pointer mt-1"
                      onClick={toggleCalendarUntil}
                    >
                      <CIcon
                        size="xl"
                        icon={cilCalendar}
                        className="text-light"
                      />
                    </div>
                    <DatePicker
                      onChangeRaw={(e) => e.preventDefault()}
                      onClickOutside={toggleCalendarUntil}
                      onInputClick={() => {
                        setVisibleCalendarSince(false);
                        toggleCalendarUntil();
                      }}
                      open={visibleCalendarUntil}
                      className="calendar-input mt-1"
                      selected={lastDate}
                      minDate={startDate}
                      onChange={(date) => {
                        setLastDate(date);
                        toggleCalendarUntil();
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          {listSettings.list && (
            <>
              <div className="overflowX">

                <MenuHeader className="d-flex pt-3 pb-3 mt-4 table-size">
                  <div className="d-flex w-100">
                    <p className="text-color section-width-2 d-block d-sm-none alert-responsive-title">
                      Alerts
                    </p>
                    <p className="text-color ps-4 alert-width-section-1 d-none d-sm-block">
                      Type
                    </p>
                    <p className="text-color alert-width-section-2 d-none d-sm-block">
                      Activated By
                    </p>
                    <p className="text-color alert-width-section-3 d-none d-sm-block">
                      Date & Time
                    </p>
                    <p className="text-color alert-width-section-4 d-none d-sm-block">
                      Location
                    </p>
                  </div>
                  <p className="text-color alert-width-section-5 d-none d-sm-block">
                    Drill Mode
                  </p>
                  <p className="text-color alert-width-section-5 d-none d-sm-block invisible">
                    &nbsp;
                  </p>
                </MenuHeader>
                {isLoading ? (
                  <div className="d-flex justify-content-center mt-4">
                    <CSpinner variant="grow" />
                  </div>
                ) : (
                  <>
                    {alerts.length < 1 ? (
                      <div className="d-flex justify-content-center">
                        <p className="text-light fs-5 mt-4">No alerts to show</p>
                      </div>
                    ) : (
                      <>
                        {alerts?.map((alert) => {
                          // @ts-ignore
                          return <AlertInformation
                            alert={alert}
                            key={alert.id}
                            setEditData={setMapData}
                          />;
                        })}
                      </>
                    )}
                  </>
                )}
              </div>

            </>
          )}
          {listSettings.settings && <AlertSettings />}
          {listSettings.tab && <AlertCategories />}

          {/*{listSettings.alert && <EmergencyAlert />}*/}
        </div>

        {listSettings.list && (
          <div className="mt-3">
            {numberOfPages > 1 && (
              <Pagination
                numberOfPages={numberOfPages}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            )}
          </div>
        )}
      </PageContainer>

      {visibleMap && (
        <MapModal
          visible={visibleMap}
          closeModal={() => setVisibleMap(false)}
          isEdit={isEdit}
        />
      )}
    </div>
  );
};

export default Alerts;
