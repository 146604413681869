import * as React from "react";
import "../../views/Alerts.scss";

//components
import { MenuHeader } from "../Atomics";
import { AlertSettingsItem } from ".";
import CIcon from "@coreui/icons-react";
import { NewAlertType } from ".";

//services
import { getAlerts } from "../../services/alertServices";
import { AlertTypeResponse } from "../../services/types";
import HandleUIError from "../../utils/HandleUIError";
import { CSpinner } from "@coreui/react";

//icons
import { cilPlus } from "@coreui/icons";
import usePermission from "../../hooks/usePermission";


const AlertSettings: React.FC = () => {
  const { checkPermission } = usePermission()
  const [visibleNewAlertType, setVisibleNewAlertType] =
    React.useState<boolean>(false);
  const [alerts, setAlerts] = React.useState<AlertTypeResponse[]>();
  const [openedModalFrom, setOpenedModalFrom] = React.useState<number>(1);
  const [selectedAlertType, setSelectedAlertType] = React.useState<string>("");
  const [selectedAlertTypeName, setSelectedAlertTypeName] =
    React.useState<string>("");
  const [selectedAlertTypeColor, setSelectedAlertTypeColor] =
    React.useState<string>("");
  const [alertCategoryType, setAlertCategoryType] = React.useState<any>([]);
  const [alertCategoryIcon, setAlertCategoryIcon] = React.useState<any>([]);
  // const [numberOfPages, setNumberOfPages] = React.useState(0);

  React.useEffect(function () {
    refreshAlerts();
  }, []);

  function showModal(
    id: typeof selectedAlertType,
    name: string,
    icon: any,
    type: any,
    color: string,
    openedFrom: number
  ): void {
    openVisibleNewAlertType();
    setOpenedModalFrom(openedFrom);
    setSelectedAlertType(id);
    setSelectedAlertTypeName(name);
    setSelectedAlertTypeColor(color);
    setAlertCategoryType(type);
    setAlertCategoryIcon(icon);
  }

  async function refreshAlerts() {
    try {
      const response = await getAlerts();
      setAlerts(response.data);
    } catch (e) {
      HandleUIError(e);
    }
  }

  function showEditModal(
    id: string,
    name: string,
    icon: any,
    type: any,
    color: string,
    openedFrom = 2
  ) {
    showModal(id, name, icon, type, color, openedFrom);
  }

  function openVisibleNewAlertType() {
    setVisibleNewAlertType(true);
    setOpenedModalFrom(1);
  }

  function closeVisibleNewAlertType() {
    setVisibleNewAlertType(false);
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <p className="text-color fs-4 mt-3 mb-2">
          Setup the sequence each type of alert will initiate.
        </p>
        {checkPermission("Create and Edit Setting") && (
            <div
                className="add-icon-container mg-lt add-icon-staff"
                onClick={openVisibleNewAlertType}
            >
              <CIcon icon={cilPlus} size="xl" className="add-icon" />
            </div>
        )}
      </div>
      <MenuHeader className="d-flex justify-content-between">
        <p className="text-color py-3 px-3">Type</p>
        <p className="text-color py-3 px-3">Category</p>
        <p className="text-color py-3 px-3 me-5">Is Home Alert</p>
        <p className="text-color py-3 px-3 me-5">Auto Send</p>
        <p className="text-color py-3 px-3 me-5">Immediate Send</p>
        <p className="text-color py-3 px-3 me-5">Default</p>
      </MenuHeader>

      {alerts ? (
        <>
          {alerts.map((alert) => {
            return (
              <AlertSettingsItem
                alertType={alert}
                key={alert.name}
                alerts={alerts}
                //there cannot be two alerts with the same name
                refreshAlerts={refreshAlerts}
                showModal={showEditModal}
                alertId={alert.id.toString()}
              />
            );
          })}
        </>
      ) : (
        <>
          <div className="d-flex justify-content-center mt-4">
            <CSpinner variant="grow" />
          </div>
        </>
      )}
      {visibleNewAlertType && (
        <NewAlertType
          refreshAlerts={refreshAlerts}
          visible={visibleNewAlertType}
          closeModal={closeVisibleNewAlertType}
          openedFrom={openedModalFrom}
          alertId={selectedAlertType}
          alertName={selectedAlertTypeName}
          alertColor={selectedAlertTypeColor}
          alertCategory={alertCategoryType}
          alertIcon={alertCategoryIcon}
          alerts={alerts}
        />
      )}
    </div>
  );
};

export default AlertSettings;
