import axiosConfig from "./axiosConfig";
import { AxiosError, AxiosResponse } from "axios";
import ApiError from "../utils/ApiError";
import { Roles } from "../components/types";

interface PaginationResponse {
  data: Array<Roles>;
  numberOfTotalParents: number;
}

export async function searchEmergencyRelation(
  limit: number,
  page: number,
  search: string
): Promise<PaginationResponse> {
  try {
    const response = await axiosConfig.get<any>(`admin/emergency-contact-relation`);
    const data = response.data.data as Array<Roles>;
    return { data, numberOfTotalParents: response.data.meta.total };
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getNewParents(
  limit: number,
  page: number
): Promise<Array<Roles>> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      `admin/all-emergency-contacts?limit=${limit}&page=${page}`
    );
    return response.data.data as Array<Roles>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getStudentId(
  student: string
): Promise<Array<Roles>> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      `admin/search-students?studentId=${student}`
    );
    return response.data.data as Array<Roles>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}


export async function getEmergencyRelations(

): Promise<Array<Roles>> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      `admin/get-emergency-relations`
    );
    return response.data.data as Array<Roles>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function saveEmergencyRelation(
  name: string,
): Promise<void> {
  try {
    const body = {
      name: name,
    };
    await axiosConfig.post("admin/emergency-contact-relation", body);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function removeEmergencyRelation(parentId: number): Promise<Array<any>> {
  try {
    const response = await axiosConfig.delete<AxiosResponse>(
      `admin/emergency-contact-relation/${parentId}`
    );
    return response.data.data as Array<any>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function editEmergencyRelation(
  parentId: number,
  name: string,
): Promise<Array<any>> {
  try {
    const body = {
      name: name,
    };
    const response = await axiosConfig.put<AxiosResponse>(
      `admin/emergency-contact-relation/${parentId}`,
      body
    );
    return response.data.data as Array<any>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}
